.FlashcardCockpit {
    height: 100vh;
    display: flex;
    /* display: grid;
    place-items: center;
    grid-template-areas:
                    "card-div"; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ScoreboardContainer {
    display: flex;
    flex-direction: row;
    padding: 0px 0px 10px 0px;
    /* margin-right: 100px; */
}

.ScoreboardText {
    color:white;
}

.CorrectScore {
    border: 2px solid black;
    background-color: lightgreen;
    color: black;
    margin: 5px 5px 5px 10px;
    width: 100px;
}

.IncorrectScore {
    border: 2px solid black;
    background-color: pink;
    color: black;
    margin: 5px 5px 5px 5px;
    width: 100px;
}

.TotalScore {
    border: 2px solid black;
    background-color: lightgray;
    color: black;
    margin: 5px 10px 5px 5px;
    width: 100px;
}

.FlashcardContainer {
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Card {
    position: absolute;
    
    transform-style: preserve-3d;
    transition: 1s transform;

    border-radius: 0.25rem;
    flex: 1;

    grid-area: card-div;
}

.flipCard {
    transform: rotateY(-180deg);
}  

.flipped {
    transform: rotateX(-180deg);
}

.CardFront {
  backface-visibility: hidden;
  box-sizing: border-box;
  padding: 1.5rem;

  background-color: #E3E3E5;
  border: 1.5px solid black;
  border-radius: 15%;
  height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 8px 8px 5px 3px lightgray; */
  font-size: 1.5rem;
}

.CardBack {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  transform: rotateY(-180deg);

  backface-visibility: hidden;
  
  /* box-sizing: border-box;
  padding: 1.5rem; */
 
  /* background-color: #E3E3E5; 
  border: 1.5px solid black;
  border-radius: 15%; */
  /* height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center; */
  /* box-shadow: 8px 8px 5px 3px lightgray;
  font-size: 1.5rem; */
}

.TCardBack {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
  
    backface-visibility: hidden;
    
    box-sizing: border-box;
    /* padding: 1.5rem; */
   
    background-color: #E3E3E5; 
    border: 1.5px solid black;
    border-radius: 15%;
    height: 350px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 8px 8px 5px 3px lightgray; */
    font-size: 1.5rem;
  }

.ButtonContainer {
    margin: 5px 0px 5px 0px;
}

.shadow {
    box-shadow: 0 -1px 1px rgba(0,0,0,0.04), 
                0 2px 2px rgba(0,0,0,0.04), 
                0 4px 4px rgba(0,0,0,0.04), 
                0 8px 8px rgba(0,0,0,0.04),
                0 16px 16px rgba(0,0,0,0.04); 
  }



/* -----Desktop-First Responsive Design Template----- */

/* Extra large devices (large laptops/desktops, 1201px and up) */
@media only screen and (min-width: 1201px) {

}

/* Large devices (laptops/desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {

} 

/* Medium devices (landscape tablets, 992px and down) */
@media only screen and (max-width: 992px) {
   
}

 /* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 768px) {

}
/* Extra small devices - Landscape (If landscape requires different parameters) */
@media only screen and (max-width: 768px) and (orientation: landscape) {

}
 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .FlashcardCockpit {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        margin: 15px 0px 5px 0px;
    }
    .ScoreboardContainer {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 25px 0px
    }
    .CorrectScore {
        border: 2px solid black;
        background-color: lightgreen;
        margin: 5px 5px 5px 5px;
        width: 75px;
    }
    .IncorrectScore {
        border: 2px solid black;
        background-color: pink;
        margin: 5px 5px 5px 5px;
        width: 75px;
    }
    .TotalScore {
        margin: 5px 5px 5px 5px;
        width: 75px;
    }
    .CardFront {
        height: 275px;
        width: 275px;
      }
    .CardBack {
        height: 275px;
        width: 275px;
    }
    .TCardBack {
        height: 275px;
        width: 275px; 
    }
}