.AddSubjectContainer {
  height: 100%;
  width: 75vw;
  color: #474745;
}

.AddSubjectTitle {
  text-align: left;
  font-size: 1.75rem;
  color: white;
  margin: 15px 0px;
}

.AddSubjectContentsContainer {
    margin: 15px 50px;
}

.AlertContainer {
  margin: 25px 25px 10px 25px;
}

.AlertContent {
  color:firebrick;
}

.Button-Field {
  width: 100%;
  background-color: #474745;

  display: flex;
  flex-direction: row;
  align-items: center;  
}

.Button-FieldDesc {
  width: 100%;
  background-color: #474745;

  display: flex;
  flex-direction: row;
  align-items: center;  
}

.SectionName {
        height: 40px;
        width: 125px;
        font-size: 0.85rem;
        /* text-align: center; */
        
        color: white;
        background-color: #9B2521;
        border: 2px solid #76211e;
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;        
}

.SectionNameCatDesc {
  height: 40px;
  width: 125px;
  font-size: 0.7rem;
  /* text-align: center; */
  
  color: white;
  background-color: #9B2521;
  border: 2px solid #76211e;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;        
}

.Button-Field button {
    height: 40px;
    width: 125px;
    font-size: 0.85rem;
    margin: 0px;
}

.Button-Field button:hover {
    background-color: #9B2521;
    border-color: #76211e;
}


.Button-Field input {
    width: 400px;

    margin: 15px;
}

.Button-FieldDesc input {
  width: 400px;

  margin: 15px;
}

.AdditionalText {
    color: firebrick;
}

.Submit {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Submit button {
    height: 40px;
    /* width: 125px; */
    font-size: 0.85rem;
    text-align: right;
    margin: 25px 11px;
}

.Submit .loading {
    margin: 0px 35px 0px 10px;
}

/* .Button-Field .PlusButton button{ 
    width: "35px";
    height: "35px"; 
    display: "flex"; 
    flex-direction: "column"; 
    align-items:"center";
    justify-content:"center"; 
    border-radius: "30px";
    padding: "0px,0px,5px,0px"; 
    margin: "15px";
} */

.AddButton {
  

}

.AddButton button{
  width: 35px;
  height: 35px;
  font-size: 0.75rem;
  border-radius: 30px;
  border: 2px solid #76211E;
  padding: 0px 0px 0px 0px;
  margin: 1px
}

.AddButton button:hover{
  width: 35px;
  height: 35px;
  font-size: 0.75rem;
  border-radius: 30px;
  border: 2px solid #592720;
  padding: 0px 0px 0px 0px;
  margin: 1px
}
  
  .loading {
    position: relative;
    width: 3rem;
    height: 3rem;
    transform-style: preserve-3d;
    perspective: 800px;
  }
  .loading .arc {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-bottom: 3px solid #9B2521;
  }
  .loading .arc:nth-child(1) {
    animation: rotate1 1.15s linear infinite;
  }
  .loading .arc:nth-child(2) {
    animation: rotate2 1.15s linear infinite;
  }
  .loading .arc:nth-child(3) {
    animation: rotate3 1.15s linear infinite;
  }
  .loading .arc:nth-child(1) {
    animation-delay: -0.8s;
  }
  .loading .arc:nth-child(2) {
    animation-delay: -0.4s;
  }
  .loading .arc:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes rotate1 {
    from {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    }
    to {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
    }
  }
  @keyframes rotate2 {
    from {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    }
    to {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
    }
  }
  @keyframes rotate3 {
    from {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    }
    to {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
    }
  }