.SingleSubjectScrollbar {
    background-color: #E3E3E5;
    color: black;
    
    width: 11.5rem;
    height: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 3.5px solid black;
    border-radius: 8px;

    margin: 25px 10px;
}