.StudyContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.StudyCategoriesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.StudyCategory {
    background-color: #9B2521;
    color: white;
    
    width: 10rem;
    height: 10rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 3.5px solid black;
    border-radius: 6px;

    margin: 25px 25px;
}

.StudyCategory:hover {
    border: 4px solid #ffe450;
}

.SubjectScrollbarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Arrow {
    color: #ffe450;
    font-size: 1.75rem;

    height: 2.90rem;

    margin: 25px 0px;
}

.Arrow:hover {
    color: #ffc400;
}

.CategoryDisplayContainer {
    width: 100vw;
    height: 100%;
    background-color: #474745;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SingleCategoryDisplayContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 25px 0px 25px 0px;
}

.Category {
    background-color: #9B2521;
    color: white;
    width: 13rem;
    height: 11.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;
    margin: 0px 15px;
}

.CategoryFlashcardSetContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columns */
    grid-template-rows: repeat(2, 1fr); /* 3 rows  */
    grid-gap: 10px 5px; /* 50 pixels of space added between rows and 30 pixels added between columns  */

    margin: 0 0 0 0;
}

.CategoryFlashcardContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.Preview {
    background-color: #E3E3E5;
    width: 8rem;
    height: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;

    margin: 0px 0px 2px 0px;
}

.Description {
    background-color: #ffe450;
    width: 7.5rem;
    height: 1rem;
    font-size: 0.60rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;

    margin: 1px 0px 1px 0px;
}