.HomeContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    color: white;
}

.LibraryButton button {
    margin: 30px 10px;
}

.CreateAll {
    margin: 0px 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columns */
    grid-template-rows: repeat(1, 1fr); /* 2 rows  */
    grid-gap: 10px 5px; 
}

.CreateSmallButton {
    grid-column: 1;
    grid-row: 1;
}

.CreateBigButton {
  grid-column: 3;
  grid-row: 1;
}

.CreateAllButton button {
    width: 215px;
    margin: 0px 0px;
}

.LoadingContainerHome {
  /* width: 100%; */
  /* grid-column: 2;
  grid-row: 2; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  padding-right: 0px;
}

.loading {
    position: relative;
    width: 3rem;
    height: 3rem;
    transform-style: preserve-3d;
    perspective: 800px;
  }
  .loading .arc {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-bottom: 3px solid #9B2521;
  }
  .loading .arc:nth-child(1) {
    animation: rotate1 1.15s linear infinite;
  }
  .loading .arc:nth-child(2) {
    animation: rotate2 1.15s linear infinite;
  }
  .loading .arc:nth-child(3) {
    animation: rotate3 1.15s linear infinite;
  }
  .loading .arc:nth-child(1) {
    animation-delay: -0.8s;
  }
  .loading .arc:nth-child(2) {
    animation-delay: -0.4s;
  }
  .loading .arc:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes rotate1 {
    from {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    }
    to {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
    }
  }
  @keyframes rotate2 {
    from {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    }
    to {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
    }
  }
  @keyframes rotate3 {
    from {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    }
    to {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
    }
  }