/* MB some of the CSS is set in the Study.css */

.Preview {
    font-size: 0.75rem;
    overflow: clip;
    text-overflow: ellipsis;
}

.PreviewLink {
    text-decoration: none;
    color: black;
}

.PreviewLink:hover {
    
    color: #9B2521;
}

.CategorySub {
    background-color: #9B2521;
    color: white;
    width: 13rem;
    height: 11.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;
    margin: 0px 15px;    
}

.CategorySub:hover {
    border: 2px solid yellow;
}
