.CategoriesPageContainer {
    height: 100%;
}

.SubjectRadioBoxGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* 2 columns */
    font-size: 0.85rem;
}

.NewDataCategoryName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 20px;
}

.NewDataCategoryDescription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    margin: 20px;
}

.NewDataWarning {
    color: red;
    margin: 0px 25px 15px 25px;
}