.CategoryContainer {
    height: 100%;
    width: 75vw;
}

.CategoryTitle {
    text-align: left;
    font-size: 1.75rem;
    color: white;
    margin: 15px 0px;
}

.CategoryGeneralContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 0px 0px 25px 0px
}


.CategoryDisplayContainerBig {
    width: 350px; 
    height: 175px; 
    background-color: #E3E3E5;
    border: 1.5px solid black;
}

.CategoryDisplayContainerSmall {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CategoryDisplaySmall {
    width: 50px; 
    height: 50px; 
    background-color: #E3E3E5;
    border: 1.5px solid black;

    margin: 15px 0px;
}

.CategoryFunctionButtons {
    display: flex;
    flex-direction: column;

    margin: 0 0 15px 0;
}

.CategoryFunctionButtons button {
    width: 350px;
    margin: 20px 35px;
}

.FlashcardContainer {
    display: flex;
    flex-direction: column;
}

.FlashcardTitle {
    text-align: left;
    font-size: 1.75rem;
    color: white;
    
    margin: 15px 0px;
}

.FlashcardFunctions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 0px;
}

.FlashcardButtons {
    margin: 0px 25px 0px 0px;
}

.FlashcardButtons button {
    margin: 0px 10px;
}

.FlashcardDisplayContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columns */
    /* grid-template-rows: repeat(2, 1fr); 3 rows  */
    grid-gap: 50px 30px; /* X pixels of space added between rows and Y pixels added between columns  */

    margin: 35px 0px;
}

.Flashcard {
    width: 200px;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #E3E3E5;
    border: 1.5px solid black;
    border-radius: 20px;
}