.SubjectContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SubjectButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 50px 50px 5px 0px;
}

.SubjectButtons .SubTitle {
    color: white;
    font-size: 1.75rem;
}

.AlertNoSubject {
    height: 500px;
    background-color: #474745;
    color: white
}

.CategoryFlashcardSetContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columns */
    grid-template-rows: repeat(1, 1fr); /* 2 rows  */
    grid-gap: 10px 5px; 

    max-height: 12rem; /*2021/08/26 -- MB this hides anything past the 2nd row*/
    overflow: hidden;
    margin: 0 0 0 0;
}

.DeleteButton {
    margin: 0px 35px;
}

.EditLayoutContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* 3 columns */
    grid-template-rows: repeat(2, 1fr); /* 2 rows  */
    grid-gap: 15px 65px; 
}

.EditLayoutContainer hr {
    margin: 3px;
}

.EditL {
    text-align: left;
    height: 100%;
}

.EditL ul li {
    list-style-type: circle;
}

.RemoveCategoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.WarningAndButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0 5px 0;
}

.EditCatWarning {
    color: red;
    font-size: 0.85rem;
}


.FormText {
    margin: 15px 0px;
}

.EditR {
    text-align: right;
}

.EditRCat {
    text-align: right;
}

.EditCategoryInput {
    margin: 0 0 0 0;
}

.EditCategoryInput input::placeholder{
    font-style: italic;
    font-size: 0.8rem;
}

.EditCatDescriptionInput input::placeholder{
    font-style: italic;
    font-size: 0.8rem;
}

.EditCatDescriptionInput {
    margin: 0 0 15px 0;
}


.EditInput {
    margin: 10px 0px;
}

.LoadingContainer1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding-right: 55px;
}

.loading {
    position: relative;
    width: 3rem;
    height: 3rem;
    transform-style: preserve-3d;
    perspective: 800px;
  }
  .loading .arc {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-bottom: 3px solid #9B2521;
  }
  .loading .arc:nth-child(1) {
    animation: rotate1 1.15s linear infinite;
  }
  .loading .arc:nth-child(2) {
    animation: rotate2 1.15s linear infinite;
  }
  .loading .arc:nth-child(3) {
    animation: rotate3 1.15s linear infinite;
  }
  .loading .arc:nth-child(1) {
    animation-delay: -0.8s;
  }
  .loading .arc:nth-child(2) {
    animation-delay: -0.4s;
  }
  .loading .arc:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes rotate1 {
    from {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    }
    to {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
    }
  }
  @keyframes rotate2 {
    from {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    }
    to {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
    }
  }
  @keyframes rotate3 {
    from {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    }
    to {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
    }
  }