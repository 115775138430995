.HeaderContainer {
    width: 100%;
    height: 13vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #353533;
    color: white;
    padding: 0 5%;
    margin: 0 0 10px 0;
  }
/* 2021/08/11 - MB - Added this class just to allow TOKEN to be displayed properly. Can remove later */
  .HeaderContainer h1 {
    margin: 0 0 0px 0;
  }

  .LogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
/* END EDIT */
  
  .HeaderContainer ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  
  .HeaderContainer li {
    margin: 0 1rem;
  }
  
  .HeaderContainer a {
    text-decoration: none;
    color: white;
    font-size: 1.25rem;
  }
  
  .HeaderContainer a:hover,
  .HeaderContainer a:active {
    color: #9B2521;
  }
  /* #ffbb00 - YELLOW? */
  .HeaderContainer button {
    width: 100px;
    font-size: 1rem;
    background-color: #ffe450;
    border: 1.5px solid black;
    border-radius: 8px;
    padding: 0.5rem 1.5rem;
    /* box-shadow: 1px 3px 5px rgba(255, 255, 255, 0.8); */
    color: #2c2922;
  }
  
  .HeaderContainer button:hover,
  .HeaderContainer button:active {
    background-color: #ffc400;
    border-color: black;
  }
  
.TinyText {
  font-size: 0.75rem;
}

  .UserLogo:hover {
    color: green;
  }