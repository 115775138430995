.AllStudyContainer {
    height: 100%;
    width: 75vw;
    background-color: #474745;
}

.AllStudyTitle {
    text-align: left;
    font-size: 1.75rem;
    color: white;
    margin: 15px 0px;
  }

.AllCategoriesContainer {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.StudyMessage {
    color: white;
}

.SingleCategoryContainer {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 25px 0;
}

.AllStudyCategoryTitle {
    background-color: #9B2521;
    color: white;
    width: 13rem;
    height: 11.5rem;
    font-size: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;
    margin: 0px 15px;
}

.CategoryFlashcardsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columns */
    grid-template-rows: repeat(1, 1fr); /* 2 rows  */
    grid-gap: 10px 5px; 

    max-height: 9.5rem; 
    overflow: hidden;
    margin: 0 0 0 0;
}

.CategoryDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .FlashcardPreview {
    background-color: #E3E3E5;
    width: 8rem;
    height: 4rem;
    font-size: 0.75rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;

    margin: 0px 0px 2px 0px;
}

.FlashcardDescription {
    background-color: #ffe450;
    width: 7.5rem;
    height: 1rem;
    font-size: 0.60rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;

    margin: 1px 0px 1px 0px;
}