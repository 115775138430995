.LoginContainer {
  height: 100%;
  background-color: #474745;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AlertContainer {
  height: 1rem;
  color: firebrick;
}

.LoginBox {
  width: 25rem;
  margin: 1rem;
  background-color: gray;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    
  border: 1.5px solid black;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  }

.Field {
    margin-bottom: 0.75rem;
  }
  
  .Field label {
    display: block;
    text-align: left;
    /* color: #616161; */
    color: black;
    /* text-transform: uppercase; */
    margin: 0 0 0.25rem 1rem;
  }
  
  .Field input {
    display: block;
    width: 20rem;
    margin: auto;
    border-radius: 4px;
    padding: 0.25rem;
    border: 1px solid black;
  }

  /* .Field input:focus {
    border: none;
    box-shadow: none;
    text-decoration: none;
  }

  .Field .validFName:focus {
    border-color: green;
    border: 3.33px solid limegreen;
  }

  .Field .invalidFName:focus {
    border: 3.25px solid red;
  }
   */
  .SubmitButton {
    margin: 25px 0px 0px 0px;
  }

  .CreateSignInButton {
    background-color: transparent;
    border: none;
    margin: 5px 0px 0px 0px;
  }

  .CreateSignInButton:hover {
    background-color: transparent;
    border: none;
    color: #ffe450;
  }