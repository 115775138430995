.Wrapper {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

.AllBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.BoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0px 25px 75px 25px
}