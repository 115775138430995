.DonateContainer {

    height: 100vh;
    font-style: normal;
    display: flex;
    flex-direction: row;
    text-align: center;
    position: relative;
    color: white;
}

.DonateContainer ul {
    list-style: none;
    padding: 0
}

.DonateContainer span {
    margin-right: 10px;
}
.DonateContainer p {
    margin-bottom: 25px;
}
.DonateContainer .coffeeP {
    margin-bottom: 10px;
}

.DonateContentContainer {
    display: flex;
    flex-direction: column;
    padding: 0 20vw 0 20vw;
}

.TextDescription {
    padding: 0 55px 0 55px;
    margin: 25px 0 50px 0;
}

.CryptoRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.btn {
    padding: 5px 10px;
    margin: 0px 5px;
}

.modal-header .btn-close:hover {
    background-color: white;
    /* border: 1px solid black; */
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-body .form-control:focus {
    /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6); */
    border-color: gray;
    box-shadow: 0 0 8px 2px #888; /* line shadow */
    /* box-shadow: 0px 8px 10px gray, 
    -10px 8px 15px gray, 10px 8px 15px gray; */
}

.modal-body img {
    margin-bottom: 15px;
    width: 200px;
    height: 200px;
}

.modal-body .btn {
    padding-left: 1em;
    margin: 0px 0px;
}

.modal-body .btn:hover {
    border-color: gray;
    box-shadow: 0 0 8px 2px #888; /* line shadow */
}

/* @media (max-width: 668px) and (orientation: landscape) {
    .DonateContainer {
      top: 8vh;
    }
  } */

@media (max-width: 666px) {
    .DonateContainer {
        font-style: normal;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: auto;
    }
}
