.Zcontainer {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Layer1 {
    position: absolute;
    background-color: white;
    z-index: 5;
    width: 200px;
    height: 200px;
    border: 1px solid black;
    /* top: 0; right: 0; bottom: 0; left: 0; */
}

.Layer2 {
    position: absolute;
    z-index: 4;
    width: 200px;
    height: 200px;
    border: 1px solid black;
}

.Layer3 {
    position: absolute;
    z-index: 3;
    width: 200px;
    height: 200px;
    border: 1px solid black;
}

.Layer4 {
    position: absolute;
    z-index: 2;
    width: 200px;
    height: 200px;
    border: 1px solid black;
}

.Layer5 {
    position: absolute;
    z-index: 1;
    width: 200px;
    height: 200px;
    border: 1px solid black;
}