button {
    font: inherit;
    cursor: pointer;
    /* background-color: #3c0080;
    border: 1px solid #3c0080; */
    background-color: #9B2521;
    border: 2.5px solid #76211e;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
  }

  
  
  button:hover,
  button:active {
    /* background-color: #5b14ac;
    border-color: #5b14ac; */
    background-color: #76211e;
    border-color: #5f251b;
  }

.ToggleButton {
    margin: 0px;
}

.ShowButton {
    width: 150px;
    margin: 25px 0px 25px 0px;
}

.FeedbackButton {
    margin: 0px 0px 0px 0px;
}

.IncorrectButton {
    margin: 0px 25px 0px 25px;
    width: 100px;
}

.CorrectButton {
    margin: 0px 25px 0px 25px;
    width: 100px;
}


/* -----Desktop-First Responsive Design Template----- */

/* Extra large devices (large laptops/desktops, 1201px and up) */
@media only screen and (min-width: 1201px) {

}

/* Large devices (laptops/desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {

} 

/* Medium devices (landscape tablets, 992px and down) */
@media only screen and (max-width: 992px) {
   
}

 /* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 768px) {

}

/* Extra small devices - Landscape (If landscape requires different parameters) */
@media only screen and (max-width: 768px) and (orientation: landscape) {

}
 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .ShowButton {
        width: 150px;
    }.IncorrectButton {
        padding: 0.5rem;
        margin: 0px 10px 0px 10px;
        width: 85px;
    }
    .CorrectButton {
        padding: 0.5rem;
        margin: 0px 10px 0px 10px;
        width: 85px;
    }

    button:hover {
        background-color: #9B2521;
        border: 2.5px solid #76211e;
    }
}