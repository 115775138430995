.UserHomeContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #474745;
}

.UserHomeCategoriesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.UserHomeCategory {
    background-color: #9B2521;
    color: white;
    
    width: 10rem;
    height: 8.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 3px solid black;
    border-radius: 6px;

    margin: 25px 25px;
}

.UserHomeCategory:hover {
    border: 4px solid #ffe450;
}

.NoSubjectText {
    color: white;
    margin: 2rem;
}

.SubjectScrollbarContainer {
    width: 75vw;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
}

.Arrow {
    color: #ffe450;
    font-size: 1.75rem;

    height: 2.90rem;

    margin: 25px 0px;
}

.Arrow:hover {
    color: #ffc400;
}

.CategoryDisplayContainer {
    width: 100vw;
    height: 100%;
    background-color: #474745;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 0 15vw;
}

.AllScrollbarItems {
    /* background-color: #474745;
    display: grid;
    grid-template-columns: repeat(3, 1fr);  3 columns
    grid-template-columns: repeat(3, 1fr);  2 rows */
    display: flex;
    flex-direction: row;
}

.SingleCategoryDisplayContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 25px 0px 25px 0px;
}

.Category {
    background-color: #9B2521;
    color: white;
    width: 13rem;
    height: 11.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;
    margin: 0px 15px;
}

.CategoryFlashcardSetContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columns */
    grid-template-rows: repeat(1, 1fr); /* 2 rows  */
    grid-gap: 10px 5px; 

    /* max-height: 9.5rem; 2021/08/26 -- MB this hides anything past the 2nd row */
    overflow: hidden;
    margin: 0 0 0 0;
}

.CategoryFlashcardContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.Preview {
    background-color: #E3E3E5;
    width: 8rem;
    height: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;

    margin: 0px 0px 2px 0px;
}

.Description {
    background-color: #ffe450;
    width: 7.5rem;
    height: 1rem;
    font-size: 0.60rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid black;
    border-radius: 6px;

    margin: 1px 0px 1px 0px;
}

.swiper {
    width: 50%;
    height: 100%;
  }

  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    width: 33%;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

.SingleSubjectScrollbar {
    font-size: 0.9rem;
}
