.SubjectHeaderSlim {
    height: 33px;
    width: 20rem;
    border: 2px solid black;
    color: white;
    background-color: #9B2521;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AllCategoriesInSubject {
    display: grid;
    grid-template-columns: repeat(4, 1fr);  
}

.IndividualCategoriesCard {
    font-size: 0.9rem;

    background-color: #E3E3E5;
    color: black;
    width: 11.5rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 3.5px solid black;
    border-radius: 8px;
    margin: 25px 10px;
}

.IndividualCategoriesCard:hover{
    border: 3.5px solid yellow;
}