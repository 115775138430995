.FlashcardsContainer {
    height: 100vh;
    width: 90%;
    background-color: #474745;

    display: flex;
    flex-direction: column;
}

.CategoryTitle {
    width: 100%;
    margin: 10px 0 0 0;
}

.ProgressBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.ProgressText {
    color: white;
    margin: 0 0 ;    
}

.ProgressVisual {
    margin: 0 25px ;
}

.CardsContainer {
    width: 100%;
    height: 315px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ButtonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px;
}

.ButtonsContainer button {
    width: 100px;
    margin: 0 15px ;
}