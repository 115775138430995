.ComponentWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.FunctionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WrapperWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.wrapper {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px; 
    border: 1px solid black;

    margin: 5px 15px;
}

.slide {
    position: absolute;
    left: -100px;
    width: 100px;
    height: 100px;
    background: skyblue;
    transition: 1s;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper:hover .slide {
    transition: 1s;
    left: 0;
}

.wrapperTwo {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px; 
    border: 1px solid black;

    margin: 5px 15px;
}

.slideTwo {
    position: absolute;
    left: -100px;
    width: 100px;
    height: 100px;
    background: skyblue;
    transition: 1s;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slideThree {
    transition: 1s;

    position: absolute;
    left: 0px;
    width: 100px;
    height: 100px;
    background: skyblue;
    transition: 1s;

    display: flex;
    flex-direction: column;
    justify-content: center;
}


.Container {
    width: 525px;
    height: 175px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: 0px solid black;

    margin: 15px 10px;
}

.Clicker {
    width: 175px;
    height: 175px;
    background-color: maroon;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 2px solid black;
    border-radius: 10px;
}

.SliderWrapper {
    position: relative;
    overflow: hidden;

    width: 250px;
    height: 150px; 
    border: 0px solid black;
}

.PreSlider {
    transition: 1s;

    position: absolute;
    left: -200px;

    width: 225px;
    height: 150px;
    background-color: salmon;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 1px solid black;
    border-radius: 8px;

    margin: 0 0 0 10px;
}

.PostSlider {
    transition: 1s;

    position: absolute;
    left: 0px;

    width: 225px;
    height: 150px;
    background-color: skyblue;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 1px solid black;
    border-radius: 8px;

    margin: 0 0 0 10px;
}

.PreSliderTwo {
    transition: 1s;

    position: absolute;
    left: -250px;

    width: 225px;
    height: 150px;
    background-color: salmon;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 1px solid black;
    border-radius: 8px;

    margin: 0 0 0 10px;
}

.PostSliderTwo {
    transition: 1s;

    position: absolute;
    left: 0px;

    width: 225px;
    height: 150px;
    background-color: skyblue;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 1px solid black;
    border-radius: 8px;

    margin: 0 0 0 10px;
}

.NoteWrapper {
    width: 25vw;
    height: 75vh;
    color: white;

    margin: 0 0 0 15px;
}

.NoteWrapper ul li {
    text-align: left;
    list-style-type: circle;
    margin: 0 0 15px 0;
}